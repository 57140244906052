








































































































import { defineComponent, ref, computed, reactive, toRefs, inject } from '@vue/composition-api';
import axios from 'axios';

export default defineComponent({
  name: 'ManageTable',
  components: {
    MTeamOwnerView: () => import('./m-team-owner-view.vue')
  },
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    teamDoc: {
      required: true,
      type: Object
    },
    viewer: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const isLoading = ref(false);
    const state = reactive({
      selectedMember: null as null | Record<string, any>,
      changeOwnerDialog: false
    });
    const viewerIsOwner = computed(() => {
      return props?.viewer?._id?.toString() === props?.teamDoc?.owner?.toString();
    });
    const isOwner = (item: Record<string, any>) => {
      return item?._id === props.teamDoc?.owner;
    };
    const tableHeaders = computed(() => {
      const headers = [
        { text: '', align: 'start', value: 'avatar', width: '5%' },
        { text: 'Name', align: 'start', value: 'name', width: '50%' },
        {
          text: 'Access',
          align: 'start',
          value: 'access',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Action',
          align: 'start',
          value: 'action',
          sortable: false,
          width: '25%'
        }
      ];
      if (props.viewer?._id.toString() === props.teamDoc?.owner.toString()) return headers;
      return headers.filter(column => column.value !== 'action');
    });
    const teamMembers = computed(() => {
      return props.teamDoc?.members?.slice().sort(member => {
        return isOwner(member) ? -1 : 1;
      });
    });
    const teamMembersExceptOwner = computed(() => {
      return props.teamDoc?.members?.filter(member => {
        return !isOwner(member);
      });
    });

    async function teamOperation(type) {
      try {
        isLoading.value = true;
        const data = {
          type,
          team_id: props.teamDoc._id,
          owner: state.selectedMember?._id
        };

        const teamRes = await axios.patch(`${process.env.VUE_APP_STUDENT_SERVICE}/team`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (teamRes.data.data) {
          isLoading.value = false;
          ctx.emit('update', teamRes.data.data);
          state.changeOwnerDialog = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    return {
      tableHeaders,
      teamMembers,
      teamMembersExceptOwner,
      isOwner,
      teamOperation,
      readonly,
      viewerIsOwner,
      isLoading,
      ...toRefs(state)
    };
  }
});
